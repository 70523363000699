@import 'Colors';
@import 'Animations';
@import 'Variables';
@import "Mixins";
@import 'Fonts';
@import 'Global';
@import "Buttons";
@import "Scrollbar";

:root {
    --navbar-color-bg: transparent !important;
}

* {
    z-index: 2;
}

header {
    box-shadow: unset !important;
}

#root {
    background-color: #430630;
}

.form {
    .control {
        margin-top: 11px;
        width: 60%;
    }

    .label {
        text-align: left;
        color: #fff !important;
    }

    .select {
        svg {
            color: $typography-dark-color !important;
        }
    }

    .input {
        border: 2px solid transparent;
        border-radius: 5px !important;
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;

        .MuiInputBase-root:before {
            content: '';
            border: unset !important;
        }

        .MuiInputBase-root:after {
            content: '';
            border: unset !important;
        }

        .MuiSelect-select {
            color: $typography-dark-color !important;
            border-radius: 5px !important;
            width: 100%;
        }

        .MuiSelect-select:before {
            content: '';
            border: unset !important;
        }

        .MuiSelect-select:after {
            content: '';
            border: unset !important;
        }

        input {
            width: 100%;
            color: $typography-dark-color !important;
        }
    }
}

.Mui-focused {
    color: $typography-dark-color !important;
    background-color: #fff !important;
}

.promotions-grid {
    margin: 70px 0 10px;

    .MuiPaper-root {
        background-color: #180515;
        border-radius: 10px !important;
        margin-bottom: 100px;
        display: flex !important;

        img {
            width: 451px;
        }

        &>div {
            margin: 0 auto;
            display: block;
        }
    }
}

@media screen and (max-width: 900px) {
    .promotions-grid {


        .MuiPaper-root {
            align-items: center;
            margin-left: 25px;
            margin-right: 25px;

            img {
                width: 330px;
                height: unset !important;
            }
        }
    }
}

@media screen and (max-width: 680px) {
    .promotions-grid {

        .MuiPaper-root {
            flex-direction: column !important;
        }
    }

    .footer .flex {
        gap: 5px !important;
    }
}


.telegram {
    margin: 0 14px;
}


.BackButton {
    right: 35px !important;
    top: 15px !important;
    position: fixed !important;
    left: unset !important;
}

.menu {
    &.header {
        .MuiPaper-root {
            background-color: rgba(0, 0, 0, 0.9) !important;
            border-radius: 10px;
            text-transform: capitalize !important;
            color: #fff !important;
            width: 150px;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 7px;
        }

        .item {
            padding: 12px 15px;

            span {
                margin-left: 10px;
            }
        }
    }

    &.mobile {
        .MuiPaper-root {
            background-color: rgba(0, 0, 0, 0.95) !important;
            border-radius: 10px;
            text-transform: capitalize !important;
            color: #fff !important;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 7px;
        }

        .item {
            padding: 12px 15px;

            span {
                margin-left: 10px;
            }
        }

        a {
            width: 100%;
        }

        button {
            width: 100% !important;
        }
    }
}

.chakra-ui-light {
    main {
        width: 100% !important;
    }
}

iframe {
    border: 0;
}

.MuiPaper-root {
    background-color: #fff;
    color: $typography-dark-color !important;
}

.MuiMenu-paper {
    background-color: #fff !important;
}

.CoinDetails {
    display: flex;
    flex-direction: column;

    .header {
        background-color: #062743;

        .top {
            display: flex;
            align-items: center;
            padding: 20px 25px;

            .large {
                font-family: $font-bold !important;
                font-size: 24px !important;
            }

            .telegram {
                .img {
                    width: 37px;
                    height: 37px;
                }
            }

            .yellow {
                color: #FECA2E;
            }

            .thumbnail {
                width: 60px;
                height: 60px;
                border-radius: 10px;
            }

            .network {
                background-color: #011B31;
                padding: 10px 12px;
                position: relative;

                .typography {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    top: 1px;
                }

                svg {
                    color: $primary-color;
                    position: relative;
                    top: -1px;
                }
            }

            .currency {
                display: flex;
                border-radius: 100px;
                align-items: center;
                gap: 4px;
                padding: 4px 8px;
                width: fit-content;
                max-width: 165px;
                overflow: hidden;

                .typography {
                    font-size: 16px;
                    position: relative;
                    top: 2px;
                    line-height: 1 !important;
                }

                img {
                    width: 26px;
                    height: 26px;
                    position: relative;
                    bottom: 1px
                }
            }
        }

        .middle {
            display: flex;
            align-items: center;
            background-color: #041D33;
            padding: 25px 30px;
            gap: 6%;
        }

        .bottom {
            display: flex;
            flex-direction: column;
            padding: 25px;
            gap: 40px;

            .socials {
                display: flex;
                gap: 18px;
                flex-wrap: wrap;
            }
        }
    }

    .details {
        background-color: #000;
        padding: 25px 20px 40px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .thumbnail {
            width: 40px;
            height: 40px;
            border-radius: 10px;
        }

        .large {
            font-family: $font-bold !important;
            font-size: 24px !important;
        }
    }
}


.modal {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // -webkit-transform: translate(-50%, -50%);
    // -moz-transform: translate(-50%, -50%);
    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    // background-color: #121212;
    // border: 2px solid #000;
    // box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    // padding: 32px;
    background-color: #062743 !important;
    box-shadow: 0px 0px 20px #FF0BE329;
    border-radius: 10px;
    border: unset !important;
    width: 674px;

    .stepper {
        margin: 25px 0;
        padding: 0 20px;

        .Mui-completed {
            color: $primary-color;
            filter: grayscale(0.25);
        }

        .Mui-active {
            color: $primary-color;
        }

        .MuiStepConnector-line {
            border-color: #344E65 !important;
        }

        .Mui-disabled {
            color: #344E65;

            svg {
                color: #344E65;
            }
        }
    }
}

.MuiContainer-root {
    padding-left: unset !important;
    padding-right: unset !important;
}

.transition {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px !important;
    z-index: 3 !important;

    &.top {
        bottom: unset !important;
        top: 0;
    }
}

.container {
    padding-top: 50px !important;
    padding-bottom: 40px !important;
    min-height: 800px;
}

.footer {
    position: relative;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 300px;

    .logo {
        padding-top: 30px;
        width: fit-content;
        margin: 0 auto;
    }
}

.wrap {
    position: relative;

    &>.MuiContainer-root {
        padding-top: 30px;
    }
}

.background {
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    position: absolute;
    width: 100%;
    object-fit: cover;
}

.coins {
    background-color: #060F43;
    padding-top: 50px;
}

.header-img {
    width: 37px;
    height: 37px;
}

.primaryTable {
    margin-top: 30px;
    margin-bottom: 30px;

    .head {
        padding-left: 12px;
        padding-right: 18px;
        padding-bottom: 5px;

        .typography {
            font-family: $font-bold !important;
            font-size: $font-size-md !important;
        }
    }

    .body {
        .cell {

            $cell-border-radius: 6px;

            background-color: #3E1835;
            border-radius: 10px;
            padding: 10px;
            margin-bottom: 25px;
            z-index: 1;
            position: relative !important;
            cursor: pointer;

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                top: calc(0px);
                left: calc(0px);
                position: absolute;
                z-index: 2;
                border-radius: 10px !important;
                background: #3E1835 !important;
            }

            &:hover {
                &:before {
                    background-color: #31132a !important;
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: calc(-1 * $cell-border-radius);
                left: calc(-1 * $cell-border-radius);
                height: calc(100% + $cell-border-radius * 2);
                width: calc(100% + $cell-border-radius * 2);
                border-radius: calc(2 * $cell-border-radius);
                z-index: -1;
                background: linear-gradient(60deg, random-bg(), random-bg(), random-bg());
                animation: animatedgradient 3s ease-in-out alternate infinite;
                background-size: 300% 300%;
            }

            .thumbnail {
                width: 60px;
                height: 60px;
                border-radius: 10px;
            }

            .MuiGrid-item {
                align-items: center;
            }

            .typography {
                font-family: $font-medium !important;
                font-size: 18px;
            }

            .telegram {
                .img {
                    width: 37px;
                    height: 37px;
                }
            }

            .large {
                font-family: $font-bold !important;
            }

            .currency {
                display: flex;
                background-color: #000;
                border-radius: 100px;
                align-items: center;
                gap: 4px;
                padding: 4px 8px;
                width: fit-content;
                max-width: 165px;
    overflow: hidden;

                .typography {
                    font-size: 16px;
                    position: relative;
                    top: 2px;
                    line-height: 1 !important;
                }

                img {
                    width: 26px;
                    height: 26px;
                    position: relative;
                    bottom: 1px
                }
            }

            .yellow {
                color: #FECA2E;
            }

            .enabled {
                display: block;
                width: 15px;
                height: 15px;
                background-color: rgb(17, 255, 235);
                border-radius: 50%;
                margin-left: 30px;
            }

            .disabled {
                display: block;
                width: 15px;
                height: 15px;
                background-color: #19010d;
                border-radius: 50%;
                margin-left: 30px;
            }

            .access {
                width: 40px;
            }
        }
    }
}

.secondaryTable {
    margin-top: 30px;
    margin-bottom: 30px;

    .head {
        padding-left: 12px;
        padding-right: 18px;
        padding-bottom: 5px;

        .typography {
            font-family: $font-bold !important;
            font-size: $font-size-md !important;
        }
    }

    .body {
        .cell {
            background-color: #192150;
            border-radius: 10px;
            padding: 10px;
            margin-bottom: 5px;
            cursor: pointer;

            &:hover {
                background-color: #121838;
            }

            .thumbnail {
                width: 60px;
                height: 60px;
                border-radius: 10px;
            }

            .MuiGrid-item {
                align-items: center;
            }

            .typography {
                font-family: $font-medium !important;
                font-size: 18px;
            }

            .telegram {
                .img {
                    width: 37px;
                    height: 37px;
                }
            }

            .large {
                font-family: $font-bold !important;
            }

            .currency {
                display: flex;
                background-color: #000;
                border-radius: 100px;
                align-items: center;
                gap: 4px;
                padding: 4px 8px;
                width: fit-content;
                max-width: 165px;
                overflow: hidden;

                .typography {
                    font-size: 16px;
                    position: relative;
                    top: 2px;
                    line-height: 1 !important;
                }

                img {
                    width: 26px;
                    height: 26px;
                    position: relative;
                    bottom: 1px
                }
            }

            .yellow {
                color: #FECA2E;
            }

            .enabled {
                display: block;
                width: 15px;
                height: 15px;
                background-color: rgb(17, 255, 235);
                border-radius: 50%;
                margin-left: 30px;
            }

            .disabled {
                display: block;
                width: 15px;
                height: 15px;
                background-color: #19010d;
                border-radius: 50%;
                margin-left: 30px;
            }

            .access {
                width: 40px;
            }
        }
    }
}

.background {
    height: 110vh;
}

@media screen and (max-width: 900px) {
    .telegram {
        gap: 10px;
    }

    .btn-menu {
        padding: 14px 7px !important;
        gap: 0px !important;

        img {
            width: 25px !important;
            height: 25px !important;
        }
    }

    .menu .header {
        padding: 5px;
    }

    .AdvertiseContainer {
        flex-direction: column !important;
        align-items: center;

        &>div {
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    .secondaryTable .body .cell {
        margin-bottom: 10px;
    }

    .secondaryTable .body .cell .access {
        width: 35px;
    }

    .primaryTable .body .cell .access {
        width: 35px;
    }

    .btn-yellow {
        padding: 10px 14px !important;
    }

    .CoinDetails .header .middle {
        padding-left: 15%;
        align-items: unset !important;
    }

    .modal {
        width: 100%;
        height: 100%;
        overflow: scroll;

        .BackButton {
            right: 15px !important;
            top: 20px !important;
        }
    }

    .form .control {
        width: 80% !important;
    }

    .container {
        min-height: unset !important;
    }

    .background {
        height: 125vh;
    }
}

@media screen and (max-width: 600px) {
    .secondaryTableIcons svg {
        display: none;
    }

    .typography.xxxlg{
        font-size: 40px !important;
    }
}