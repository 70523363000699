.btn {
    border: none;
    font-size: 14px !important;
    font-family: $font-semiBold !important;
    font-weight: normal !important;
    line-height: 1.2 !important;
    font-weight: bold !important;
    text-transform: unset !important;
    padding: 16px 28px !important;
    -webkit-font-smoothing: subpixel-antialiased;
    transition: all 0.15s linear !important;
    border-radius: 10px !important;
}

.btn:hover {
    filter: brightness(1.10);
}

.btn:active {
    filter: brightness(1.15);
}

$borderWidth: 5px;

.btn-primary {
    padding: 18px 33px !important;
    color: $primary-color !important;
    // border: 7px solid purple !important;
    font-family: $font-bold !important;
    position: relative !important;
    border-radius: $borderWidth !important;
    z-index: 1;

    &>span {
        position: relative;
        top: 2px;
    }
}

.btn-primary:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: $borderWidth * 2 !important;
    background: $secondary-color !important;
}

.btn-primary:after {
    content: '';
    position: absolute;
    top: calc(-1 * $borderWidth);
    left: calc(-1 * $borderWidth);
    height: calc(100% + $borderWidth * 2);
    width: calc(100% + $borderWidth * 2);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * $borderWidth);
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}

.btn-secondary {
    color: $secondary-color !important;
    background-color: transparent !important;
    border: 3px solid #FFFFFF !important;

    &>span {
        position: relative;
        top: 2px;
    }
}

.btn-third {
    color: $primary-color !important;
    background-color: transparent !important;
    border: 3px solid $primary-color !important;

    &>span {
        position: relative;
        top: 2px;
    }
}

.btn-third-borderless {
    color: $primary-color !important;
    background-color: transparent !important;
    border: 3px solid transparent !important;

    &>span {
        position: relative;
        top: 2px;
    }

    &:hover {
        border: 3px solid $primary-color-rgb-hover !important;
    }

    &:active {
        border: 3px solid $primary-color !important;
        filter: unset !important;
    }
}

.btn-fourth {
    color: $typography-color !important;
    background-color: $primary-color !important;

    &>span {
        position: relative;
        top: 2px;
    }
}

.btn-blue {
    background-color: #40B3E0 !important;
    color: white !important;
    padding: 12px 25px !important;
    font-size: 16px !important;
    padding-left: 15px !important;
    img {
        width: 20px;
    }
    span{
        position: relative;
        top: 3px;
        margin-left: 7px;
    }
}

.btn-blue.nobg{
    background-color: #041D33 !important;
    padding: 16px 25px !important;
    &:hover{
        background-color: #001222 !important;
    }
}

.btn-yellow {
    background-color: #EBE316 !important;
    color: $primary-color !important;
    font-family: $font-bold !important;
    font-weight: bold !important;
    padding: 12px 22px !important;
    padding-left: 15px !important;
    font-size: 16px !important;
    border-radius: 15px !important;
    box-shadow: 0px 0px 20px #EB168B;
    border: 6px solid #a721ac !important;
    span {
        margin-left: 10px;
        position: relative;
        top: 3px;
    }
}

.btn-borderless {
    color: $typography-color !important;
    background-color: transparent !important;

    &>span {
        position: relative;
        top: 2px;
    }

    &:hover {
        box-shadow: inset 0px 0px 5px rgba(255, 255, 255, 0.2);
    }

    &:active {
        box-shadow: inset 0px 0px 5px rgba(255, 255, 255, 0.5);
    }
}

.btn-menu {
    gap: 4px;
    padding: 16px 12px !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
    border-radius: 10px;
    text-transform: capitalize !important;
    color: $typography-color !important;

    &>.text {
        position: relative;
        top: 2.5px;
    }

    &>.MuiButton-startIcon {
        margin-left: 4px;
    }
}