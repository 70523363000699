$font-regular: "Spartan", Arial, sans-serif;
$font-medium: "SpartanMedium", Arial, sans-serif;
$font-bold: "SpartanBold", Arial, sans-serif;
$font-semiBold: "SpartanSemiBold", Arial, sans-serif;
$font-thin: "SpartanThin", Arial, sans-serif;

@font-face {
    font-family: 'Effra Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Heavy'), url('../fonts/effra/Effra\ Heavy.woff') format('woff');
}

@font-face {
    font-family: 'Effra Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Heavy Italic'), url('../fonts/effra/Effra\ Heavy\ Italic.woff') format('woff');
}


@font-face {
    font-family: 'Effra Light';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Light'), url('../fonts/effra/Effra\ Light.woff') format('woff');
}


@font-face {
    font-family: 'Effra Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Medium'), url('../fonts/effra/Effra\ Medium.woff') format('woff');
}


@font-face {
    font-family: 'Effra Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Italic'), url('../fonts/effra/Effra\ Italic.woff') format('woff');
}


@font-face {
    font-family: 'Effra Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Light Italic'), url('../fonts/effra/Effra\ Light\ Italic.woff') format('woff');
}


@font-face {
    font-family: 'Effra Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Medium Italic'), url('../fonts/effra/Effra\ Medium\ Italic.woff') format('woff');
}


@font-face {
    font-family: 'Effra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Bold'), url('../fonts/effra/Effra\ Bold.woff') format('woff');
}


@font-face {
    font-family: 'Effra Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Bold Italic'), url('../fonts/effra/Effra\ Bold\ Italic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Thin';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'montserratThin';
    font-style: normal;
    font-weight: normal;
    src: local('montserratThin'), url('../fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'montserratRegular';
    font-style: normal;
    font-weight: normal;
    src: local('montserratRegular'), url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'montserratMedium';
    font-style: normal;
    font-weight: normal;
    src: local('montserratMedium'), url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'montserratBold';
    font-style: normal;
    font-weight: normal;
    src: local('montserratBold'), url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'montserratItalic';
    font-style: normal;
    font-weight: normal;
    src: local('montserratItalic'), url('../fonts/montserrat/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: normal;
    src: local('montserratRegular'), url('../fonts/spartan/Spartan-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SpartanMedium';
    font-style: normal;
    font-weight: normal;
    src: local('SpartanMedium'), url('../fonts/spartan/Spartan-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'SpartanBold';
    font-style: normal;
    font-weight: normal;
    src: local('SpartanBold'), url('../fonts/spartan/Spartan-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'SpartanSemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('SpartanSemiBold'), url('../fonts/spartan/Spartan-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'SpartanThin';
    font-style: normal;
    font-weight: normal;
    src: local('SpartanThin'), url('../fonts/spartan/Spartan-Thin.ttf') format('truetype');
}

body {
    font-family: $font-medium;
    font-size: $font-size;
}
